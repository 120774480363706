<template>
	<div>
		<div class="sub-nav-bar">
			<div class="para-info">
				<b></b>
				<div  v-for="sub in subs" :key="sub.path" :class="$route.path==sub.path?'selected':''" @click="goTo(sub.path)">{{sub.meta.title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SubNavbar',
		props: {
			subs: {
				type: Array,
				required: true
			}
		},
		methods:{
			goTo:function(path){
				this.$router.push(path);
			}
		}
	}
</script>

<style lang="scss" scoped="true">


		.sub-nav-bar {
			display: flex;
			justify-content: center;
			margin-top: 50px;

			.para-info {
				display: flex;
				width: 1100px;
				align-items: center;
				margin-bottom: 40px;

				b {
					height: .2em;
					width: 1em;
					display: block;
					background-color: $primary-red;
				}

				div {
					padding: 5px 1em;
					border-radius: 2em;
					color: $primary-font-color-light;
					border: 1px solid $primary-font-color-light;
					margin-left: 1.5em;
					cursor: pointer;
				}

				.selected {
					background-color: $primary-red;
					color: #fff;
					border: 1px solid $primary-red;
				}
			}
		}
	
</style>
