<template>
	<div>
		<subnav :subs="subs"></subnav>
		<div class="flex-container">
			<div class="flex-content">
				<div v-for="(item,index) in history" :key="index">
					<div>
						<span :class="index%2==0?'big-round-gray':'big-round-yellow'">{{item.year}}</span>
					</div>
					<div :class="index%2==0?'line-gray':'line-yellow'" v-for="(event,eventIndex) in item.events"
						:key="eventIndex">
						<span>
							<span>
								<i></i>
							</span>
						</span>
						<div>{{event}}</div>
					</div>

				</div>

			</div>

		</div>


	</div>
</template>

<script>
	import SubNavbar from "@/components/common/SubNavbar"
	export default {
		name: 'ContentPage',
		data() {
			return {
				subs: [],
				history: [{
						year: "2020",
						events: ["电力/电网物联网系统", "人脸识别系列产品开发", "物联网云控平台开发"]
					},
					{
						year: "2019",
						events: ["智能物联网技术研究", "智慧景区", "智慧园区", "智慧楼宇"]
					},
					{
						year: "2018",
						events: ["深圳研发部成立", "物联网技术研究", "图像识别技术", "语音识别测评系统", "流媒体直播技术"]
					},
					{
						year: "2017",
						events: ["AI技术、语音识别技术研究", "金融社保、教育行业", "图像识别技术", "人脸识别技术", "AI智能辅助学习系统"]
					},
					{
						year: "2016",
						events: ["大数据技术研究", "旅游大数据平台", "航司运价大数据系统", "OAT政策发布系统"]
					},
					{
						year: "2015",
						events: ["2015年4月公司成立", "飞机票数据接口", "火车票数据接口", "火车票数据接口", "OTA订单系统"]
					}
				]
			}
		},
		mounted() {
			this.genSubNavbar();
		},
		watch: {
			$route() {
				this.genSubNavbar();
			}
		},
		methods: {
			genSubNavbar: function() {
				let self = this;
				var queryData = this.$router.options.routes.filter(function(_data) {
					return _data.meta != null && _data.meta.index == self.$route.meta.index
				});
				this.subs = queryData;
			}
		},
		components: {
			"subnav": SubNavbar
		}
	}
</script>

<style lang="scss" scoped="true">
	.flex-container {
		display: flex;
		justify-content: center;
		margin-bottom: 20px;

		.flex-content {
			width: 800px;

			.big-round-gray {
				width: 50px;
				height: 50px;
				display: inline-block;
				background-image: linear-gradient(rgb(114, 123, 155), rgb(87, 92, 121));
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				box-shadow: 1px 1px 2px #888888;
			}

			.line-gray {
				display: flex;
				align-items: center;

				span {
					display: inline-block;
					width: 25px;
					height: 50px;
					border-right: 2px solid rgb(217, 220, 232);
					position: relative;

					span {
						width: 16px;
						height: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: rgba(151, 160, 180, .5);
						border-radius: 50%;
						border: 1px solid rgb(181, 187, 202);
						position: absolute;
						top: 17px;
						right: -10px;

						i {
							background: rgb(108, 115, 147);
							width: 8px;
							height: 8px;
							border-radius: 50%;
							display: block;
						}
					}
				}

				div {
					margin-left: 20px;
					color: $primary-font-color;
				}
			}


			.big-round-yellow {
				width: 50px;
				height: 50px;
				display: inline-block;
				background-image: linear-gradient(rgb(255, 218, 33), rgb(255, 186, 33));
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				box-shadow: 1px 1px 2px #888888;
			}

			.line-yellow {
				display: flex;
				align-items: center;

				span {
					display: inline-block;
					width: 25px;
					height: 50px;
					border-right: 2px solid rgb(253, 223, 155);
					position: relative;

					span {
						width: 16px;
						height: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: rgba(254, 224, 154, .5);
						border-radius: 50%;
						border: 1px solid rgb(254, 216, 128);
						position: absolute;
						top: 17px;
						right: -10px;

						i {
							background: rgb(254, 187, 35);
							width: 8px;
							height: 8px;
							border-radius: 50%;
							display: block;
						}
					}
				}

				div {
					margin-left: 20px;
					color: $primary-font-color;
				}
			}
		}
	}
</style>
